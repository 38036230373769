@tailwind base;
@tailwind components;
@tailwind utilities;

/* Additional custom styles */
html {
  scroll-behavior: smooth;
}

/* Dark mode styles */
@media (prefers-color-scheme: dark) {
  body {
    @apply bg-dark text-gray-100;
  }
}

/* High contrast mode support */
@media (prefers-contrast: high) {
  :root {
    --tw-text-opacity: 1;
    --tw-bg-opacity: 1;
  }
}

/* Focus styles for accessibility */
*:focus {
  outline: 2px solid rgb(110, 32, 155);
  outline-offset: 2px;
}

/* Skip to main content link for keyboard navigation */
.skip-to-main-content-link {
  position: absolute;
  left: -9999px;
  z-index: 999;
  padding: 1em;
  @apply bg-white dark:bg-dark-surface text-black dark:text-white;
  opacity: 0;
}

.skip-to-main-content-link:focus {
  left: 50%;
  transform: translateX(-50%);
  opacity: 1;
}
